.SousCategorieContener {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: aqua;
}
.SousCategorieRow {
  padding: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: brown;
  border-radius: 5px;
  width: 80%;
  text-align: center;
}
.SousCategorieRow:hover {
  background-color: #ff7f50;
  cursor: pointer;
}
