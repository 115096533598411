.ProductLine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.ProductLineSelected {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 2px solid #007bff;
}
.ProductLineActif {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 2px solid #ff0015;
}

.ProductLine .ProductImage {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

.ProductLine .ProductInfo {
  flex: 1;
  margin-left: 10px;
}

.ProductLine .ProductName {
  font-weight: bold;
  font-size: 16px;
  color: #333;
}

.ProductLine .ProductPrice {
  font-size: 14px;
  color: #999;
}

.ProductLine .ProductActions {
  display: flex;
  align-items: center;
}

.ProductLine .ProductActions button {
  margin-left: 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.ProductLine .ProductActions button:hover {
  background-color: #0056b3;
}
