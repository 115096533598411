.PosteConfection {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-height: 50vh;
  overflow-y: auto;
}
.OrderBox {
  display: flex;
  flex-direction: column;
  max-width: 150px;
  border-style: solid;
  border-radius: 10px;
  justify-content: flex-start; /* Aligne les éléments sur le bord gauche */
  background-color: aqua;
  margin-right: 5px;
  margin-bottom: 5px;
}
.OrderIDOrdderBox {
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.177);
}
.OrderProductName {
  padding: 5px;
  height: 100%;
}
.ProductSup {
  color: rgb(255, 0, 0);
  font-size: large;
  font-weight: bolder;
}

.ButtonFinalisee {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  display: flex;
  background-color: rgba(0, 0, 0, 0.177);
  justify-content: center; /* Centre les éléments horizontalement */
  align-items: center; /* Centre les éléments verticalement */
  cursor: pointer;
}
