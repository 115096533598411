.navTablePrepMatiere {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2rem;
}
.RowButton {
  background-color: aquamarine;
  border-radius: 5px;
  padding: 0.5rem;
  cursor: pointer;
}
.RowButton:hover {
  background-color: #00bfff;
}
.RowSelected {
  border-radius: 5px;
  padding: 0.5rem;
  cursor: pointer;
  background-color: #00bfff;
}
