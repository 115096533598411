.ContenerSelectFournisseur {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 300px;
  overflow-y: auto;
}

.RowSelectFournisseur {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 15px;
  border-radius: 15px;
  background-color: #f5f5f5;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}
.RowSelectFournisseur:hover {
  background-color: #e5e5e5;
}
.SelectFournisseur {
  width: 100%;
  padding: 10px;
  border-radius: 15px;
  border: none;
  background-color: #f5f5f5;
  font-size: 1.2em;
  font-weight: bold;
  color: #000;
  text-align: center;
}
