.ContenerTable {
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow-y: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
}
.RowMatiere {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
  gap: 15px;
}
.MainDataDisplay {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  max-height: 100px;
}
.ConssomationTheoriquePrep {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
}
.RowConssomationTheoriquePrep {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
  gap: 15px;
  border-bottom: 1px solid rgb(195, 45, 255);
}
.navAnaylseCommande {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.navRow {
  width: 50%;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}
.navRowSelected {
  background-color: rgb(195, 45, 255);
  width: 50%;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}
