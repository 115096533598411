.Row {
  display: flex;
  flex-direction: row;
}

.link-refaire {
  cursor: pointer;
  color: brown;
}
.Contener-RefaireInv {
  overflow-y: auto;
  max-height: 500px;
}
