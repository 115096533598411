.search-bar-container {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 200px;
}
.input-wrapper {
  background-color: white;
  width: 100%;
  border-radius: 10px;
  height: 2.5rem;
  padding: 0 15px;
  box-shadow: 0px 0px 8px #ddd;
  display: flex;
  align-items: center;
}

input {
  background-color: transparent;
  border: none;
  height: 100%;
  font-size: 1.25rem;
  width: 100%;
  margin-left: 5px;
}
input:focus {
  outline: none;
}
#search-icon {
  color: royalblue;
}
.result-list {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 8px #ddd;
  border-radius: 10px;
  margin-top: 1rem;
  max-height: 300px;
  overflow-y: scroll;
}

.search-result {
  padding: 10px 20px;
}

.search-result:hover {
  background-color: #efefef;
}
