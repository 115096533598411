.ContenerOrder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #f2f2f2;
  overflow: auto;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.ContenerOrder:hover {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  cursor: pointer;
}
.ContenerOrderSelected {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #f2f2f2;
  overflow: auto;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  border: 2px solid #ffbf00;
}

.ContenerProdUsedPrep {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 80%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #f2f2f2;
  overflow: auto;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}
.RowProdUsedPrep {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #f2f2f2;
  overflow: auto;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}
