.ContenerSelectPrepRefaitForPrepID {
  display: flex;
  flex-direction: column;
}
.RowSelectPrepRefaitSelected {
  display: flex;
  flex-direction: row;
  justify-content: center; /* Alignement horizontal au centre */
  align-items: center;
  margin: 5px 0;
  cursor: pointer;
  background-color: brown;
  padding: 10px;
  border-radius: 10px;
  font-size: 10px;
  color: white;
  font-weight: bold;
}
.RowSelectPrepRefait {
  display: flex;
  flex-direction: row;
  justify-content: center; /* Alignement horizontal au centre */
  align-items: center;
  margin: 5px 0;
  cursor: pointer;
  background-color: rgb(222, 119, 119);
  padding: 10px;
  border-radius: 10px;
  font-size: 10px;
  color: white;
  font-weight: bold;
}
