.DisplayProduct {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  overflow-y: auto;
  max-height: 600px;
  gap: 10px;
}

.VignetteProduit {
  margin-left: 10px;
  margin-right: 10px;
  padding: 15px;
  display: grid;
  grid-template-columns: 1fr 5fr;

  background-color: aquamarine;
  border-radius: 5px;
}
.imgProd {
  max-height: 80px;
}
.Description {
  margin-left: 10px;
}
