.RowRefMatiere {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background-color: #f5f5f5;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.RowRefIngredientMatiere {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #f87979;
  border-radius: 5px;
  background-color: #f5f5f5;
  box-shadow: 0 0 5px 0 rgba(249, 96, 96, 0.1);
  transition: all 0.3s ease;
}
.RowRefMatiereSelected {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background-color: #f5f5f5;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  background-color: #f87979;
  color: white;
}
