.displayMatiereUsed {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  padding: 10px;
  border: 1px solid black;
  border-radius: 5px;
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 500px;
  max-width: 500px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: black;
  font-family: "Roboto", sans-serif;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}
