.grid-container {
  display: grid;
  grid-template-columns: 3fr 1fr;
  border: 1px solid black;
  margin-bottom: 5px;
}

.grid-container-title {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid black;
  margin-bottom: 5px;
  background-color: #e6e6e6;
  font-weight: bold;
}
