.CardOrder {
  background: linear-gradient(to right, #ff66b2, #ff0066);
  height: 150px;
  overflow: auto;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 15px;
}
.DisplayPrep {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.DisplayPrep > div {
  margin-right: 10px; /* Espace entre les éléments enfants */
}
.img-Prep {
  width: 30px;
  border-radius: 50%;
}
