.MainFicheTechniQuePrep {
  background-color: rgba(165, 42, 42, 0.375);
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  padding: 10px;
  max-height: 300px;
}
.Ingredient {
  width: 50%;
  background-color: rgba(0, 0, 0, 0.32);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-right: 10px;
  padding: 10px;
  overflow-y: auto;
}

.ExplicationPrep {
  width: 50%;
  background-color: rgba(206, 20, 20, 0.32);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-left: 10px;
  padding: 10px;
  overflow-y: auto;
}
.SingleIngrediant {
  display: flex;
  flex-direction: row;
  padding: 10px;
}
