.MainDashMarketingPage {
  display: grid;
  grid-template-columns: 3fr 1fr;
}
.rightSideMarketing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
