.CategorieFood {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: azure;
  gap: 10px;
  color: white;
}

.CategorieRow {
  padding: 15px;
  background-color: brown;
  border-radius: 5px;
}

.CategorieRow:hover {
  background-color: #ff7f50;
  cursor: pointer;
}
