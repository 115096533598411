.SideBarMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ButtonMenuItem1 {
  height: 50vh;
  width: 100%;
  border-radius: 5px;
  background-color: crimson;
  display: flex; /* Utilise display: flex */
  justify-content: center; /* Centre horizontalement */
  align-items: center; /* Centre verticalement */
  transition: background-color 0.3s;
}
.ButtonMenuItem2 {
  height: 50vh;
  width: 100%;
  border-radius: 5px;
  background-color: rgb(106, 17, 249);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s, filter 0.3s; /* Ajoutez filter à la transition */
}

.ButtonMenuItem2:hover {
  background-color: rgb(106, 17, 249); /* Couleur de fond inchangée au survol */
  filter: brightness(0.9); /* Assombrit la luminosité de l'élément */
}

.ButtonMenuItem1:hover {
  background-color: lightcoral; /* Nouvelle couleur au survol */
}
