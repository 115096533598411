.MainFicheTechniquePrep {
  display: flex;
  gap: 10px;
}
.ParentFicheTechniquePrep {
  width: 100%;
}
.Matiere-Qte {
  color: brown;
  font-size: 22px;
}
