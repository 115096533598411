.ContenerProduitActifPrepAndMatiereAuResto {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255);
  border-radius: 0.7rem;
  gap: 5px;
  font-size: 13px;
  overflow-y: auto;
}
