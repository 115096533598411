.NameConditionnement {
  padding: 10px;
  margin: 10px;
  text-align: center;
  background-color: rgba(165, 42, 42, 0.49);
}
.contenerMateiereContition {
  display: flex;
  padding: 8px;
}
.QteMatiereConditionnement {
  color: chocolate;
  margin-right: 8px;
  margin-left: 8px;
}
