.SwitchButtonPrepIngredient {
  padding: 15px;
  background-color: red;
  max-width: 150px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}

.SwitchButtonPrepIngredient:hover {
  background-color: bisque;
}
