.ContenerHeaderChoixDuRestaurant {
  display: flex;
  justify-content: space-between; /* Pour placer les éléments à l'extrême gauche et à l'extrême droite */
  align-items: center; /* Pour centrer verticalement les éléments */
}

.TextBienvenue {
  /* Styles pour le texte Bienvenue */
  font-size: 18px;
  font-weight: bold;
  text-align: center; /* Centrer horizontalement */
  margin: 0 auto; /* Centrer verticalement */
  max-width: 260px;
}

.choixresto {
  /* Styles pour la div "Choix du restaurant" */
  background-color: #d5dee8;
  color: black;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}
