.CreateInventaire {
  overflow-y: auto;
  max-height: 500px;
}

.RowMatiere {
  display: grid;
  padding: 5px;
  background-color: azure;
  grid-template-columns: 40% 15% 15% 15% 15%;
  border-color: black;
  border: 1px solid black;
}

.TrieMatiere {
  display: grid;
  grid-template-columns: auto auto;
  gap: 5px;
}

.menuItem1 {
  border-radius: 10px;
  background: pink;
  padding: 5px;
}
