.DisplayProductAndPrep {
  margin-left: 25px;
  width: 85%;
  height: 100%;
  overflow-y: auto;
  background: rgba(255, 255, 255);
  border-radius: 0.7rem;
  padding: 1rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  font-size: 13px;
}
