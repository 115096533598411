.ChargeContener {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
}
.ChargeData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 5px;
}
.ChargeDataTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  height: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.ChargeDataValue {
  display: flex;
  flex-direction: row;
  justify-content: center; /* Centrer horizontalement */
  align-items: center;
  width: 90%;
  height: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #f2f2f2; /* Couleur de fond */
  color: red; /* Couleur du texte */
  border: 2px solid #999999; /* Bordure */
  padding: 10px; /* Espacement interne */
  font-weight: bold; /* Texte en gras */
  text-align: center; /* Alignement du texte */
}
