.ButtonChangeDefaultRefMatiereForIngredient {
  border: 1px solid rgb(0, 0, 0);
  padding: 5px;
  border-radius: 10px;
  margin-left: 1rem;
  color: white;
  background-color: #283747;
  font-size: 1rem;
  cursor: pointer;
  text-align: center; /* Added to center the text */
}
.ButtonChangeDefaultRefMatiereForIngredient:hover {
  background-color: rgb(250, 247, 247);
  color: rgb(228, 55, 55);
  transition: 0.5s;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.3rem;
}
