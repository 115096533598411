.FichesTechnique {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}

.IngredientFiche {
  background-color: rgba(105, 118, 238, 0.212);
  max-width: 150px;
}
.img-prep {
  width: 100px;
  height: 100px;
}
