.ContenerDetailSelectedRefairePrep {
  display: flex;
  flex-direction: column;
}
.QteRefaitDisplay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: 100%;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background-color: #f5f5f5;
  max-height: 55px;
  font-size: 16px; /* Gros */
  font-weight: bold; /* Gras */
  color: red; /* Rouge */
  text-align: center; /* Au centre */
}
