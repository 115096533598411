.CardOrderPrepInterface {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  background-color: rgba(240, 248, 255, 0.7);
  margin: 15px;
  padding: 15px;
  max-height: 25vh;
}
.CardOrderPrepInterfaceSelected {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  background-color: rgb(0, 255, 85);
  margin: 15px;
  padding: 15px;
  max-height: 25vh;
}
.EndDLC {
  background-color: rgb(255, 0, 183);
}
.CardPhoto {
  max-width: 150px;
}
