.navAnaylseCommande {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.navRow {
  width: 50%;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}
.navRowSelected {
  background-color: rgb(195, 45, 255);
  width: 50%;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 15px;
  color: white;
  font-weight: bold;
}
