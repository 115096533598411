.Row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  gap: 10px;
  background-color: aquamarine;
}
.contener {
  padding: 10px;
  max-height: 700px;
  overflow-y: auto;
}
