.navBarStock {
  margin-top: 10px;
  background-color: #f2f2f2;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
  max-height: 35px;
  border: 2px solid #ddd;
  border-radius: 15px;
  gap: 10px;
}
.cell {
  cursor: pointer;
  border-right: 3px solid #000000;
}
.cell:hover {
  background-color: yellow;
}
.cellSelected {
  cursor: pointer;
  background-color: beige;
  border-right: 3px solid #000000;
}
