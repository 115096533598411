.CommandeArea {
  background-color: aquamarine;
  margin-bottom: 10px;
}
.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
.AllOrderDisplay {
  max-height: 400px;
  overflow-y: auto;
}
