.ContenerSelectorIngredient {
  margin-left: 25px;
  width: 70%;
  height: 100%;
  background: rgba(255, 255, 255);
  border-radius: 0.7rem;
  padding: 1rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  max-height: 80vh;
  overflow-y: auto;
}

.RowSelectorIngredient {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 0.5);
  transition: 0.3s;
  cursor: pointer;
}

.RowSelectorIngredient:hover {
  background: rgba(201, 55, 55, 0.7);
}
.RowSelectorIngredientSelected {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: rgba(201, 55, 55, 0.7);
  transition: 0.3s;
  cursor: pointer;
}
