.ContenerDisplayStock {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 380px;
  background: rgba(255, 255, 255);
  border-radius: 0.7rem;
  gap: 1rem;
  font-size: 13px;
  overflow-y: auto;
}
.DisplayStock {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.rechercheDansStock {
  margin-left: auto;
  margin-right: auto;
}
