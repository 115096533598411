.SelctionCommandes {
  max-height: 325px;
  overflow-y: auto;
  /*border: 2px solid;*/
  border-radius: 5px;
  border-color: black;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ProductBox {
  flex-direction: column;
  max-width: 150px;
  border-style: solid;
  border-radius: 10px;
  justify-content: flex-start; /* Aligne les éléments sur le bord gauche */
  background-color: rgba(0, 255, 94, 0.742);
  margin-right: 5px;
}
.ProductBoxGroup {
  flex-direction: column;
  max-width: 150px;
  border-style: solid;
  border-radius: 10px;
  justify-content: flex-start; /* Aligne les éléments sur le bord gauche */
  background-color: rgba(255, 0, 157, 0.742);
  margin-right: 5px;
}

.ProductName {
  padding: 10px;
}

@media screen and (max-width: 768px) {
  .SelctionCommandes {
    justify-content: space-evenly;
  }
}
