.ContenerModificationDish {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 1fr;
  background: rgba(255, 255, 255);
  border-radius: 0.7rem;
  padding: 1rem;
  gap: 1rem;
  max-height: 80vh;
  overflow-y: auto;
}
