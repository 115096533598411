.ContenerBottomPage {
  padding: 10px;
  margin-right: 5px;
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow-x: auto;
  border-radius: 24px;
  border: 1px solid #e5e5e5;
}

.StyleImgPrep {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.Box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-left: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.NomPrep {
  border-radius: 5px;
}

.RechecheBox {
  display: flex;
  flex-direction: column;
  padding: 25px;
  min-width: 200px;
  max-width: 200px;
  background-color: rgba(255, 255, 255, 0.8);
}

.ContenerSelectionCategories {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-left: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-top: 5px;
}

.CategorieContener {
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  background-color: #f5f5f5;
}

.CategorieContener:hover {
  background-color: #e5e5e5;
}
