.SelectIngredientContener {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  box-shadow: 0px 0px 10px 0px #000000;
  margin-top: 10px;
  margin-bottom: 10px;
}
.SelectFournisseurContener {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  box-shadow: 0px 0px 10px 0px #000000;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ListeIngredient {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  box-shadow: 0px 0px 10px 0px #000000;
  margin-top: 10px;
  margin-bottom: 10px;
}

.SelectIngredient {
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px #000000;
  padding: 5px;
}
.SelectedIngredient {
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px #c68787;
  padding: 5px;
  margin: 5px;
}
