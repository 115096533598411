.NavBarTwoChoice {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid black;
}
.navRowTwoChoice {
  width: 50%;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}
.navRowTwoChoiceSelected {
  background-color: rgba(45, 111, 255, 0.44);
  width: 50%;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  color: white;
  font-weight: bold;
}
