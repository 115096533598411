.header {
  grid-row: 1; /* Place the header in the first row */
  grid-column: 1;
  background: linear-gradient(
    to right,
    rgb(208, 244, 223, 255),
    rgb(0, 0, 0, 255)
  );

  display: flex;
  justify-content: center;
  align-items: center;
}

.Img {
  max-height: 25px;
  margin-right: 1rem;
}
.Help {
  margin-left: 1rem;
  color: white;
  font-size: 2.2rem;
  cursor: pointer;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Button {
  border: 1px solid white;
  padding: 10px;
  border-radius: 10px;
  margin-left: 1rem;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
}
.Button:hover {
  background-color: rgb(250, 247, 247);
  color: rgb(228, 55, 55);
  transition: 0.5s;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.3rem;
}
