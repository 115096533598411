.ContenerRefairePrepAuRestaurant {
  display: grid;
  grid-template-columns: 3fr;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255);
  border-radius: 0.7rem;
  gap: 1rem;
  font-size: 13px;
  max-height: 80vh;
  overflow-y: auto;
}
