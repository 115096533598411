.Creation-produit {
  display: flex;
  gap: 50px;
}

@media screen and (max-width: 1200px) {
  .Creation-produit {
    flex-direction: row;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 957px) {
  .Creation-produit {
    flex-direction: column;
    margin-bottom: 5px;
  }
}
