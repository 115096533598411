.NotSelectedOrder {
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.NotSelectedOrder:hover {
  background-color: #e0e0e0;
}
.SelectedOrder {
  background-color: #6a90ff;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background-color: #e0e0e0;
}
.SelectedProduct {
  background-color: #6a90ff;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background-color: #e0e0e0;
}
.SelectedProduct:hover {
  background-color: #6a90ff;
}
.NotSelectedProduct {
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
