.ContenerDisplayActifProductInTheRestaurant {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255);
  border-radius: 0.7rem;
  font-size: 13px;
  overflow-y: auto;
}
.SearchBarProductInTheRestaurant {
  margin-left: auto;
  margin-right: auto;
}
.RowProductActif {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
}
