.ContenerSelectInventaire {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.ContenerSlector {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
