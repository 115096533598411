.DisplayOrderMatere {
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px black;
  background-color: antiquewhite;
}
.row {
  margin-bottom: 5px;
}
