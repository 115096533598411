.DisplayOrderPrepLogistic {
  background-color: bisque;
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
  border-radius: 10px;
}
.PrepOrder {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.imgPrep {
  border-radius: 50%;
  max-width: 100px;
  max-height: 100px;
}
.DateOrder {
  padding: 15px;
  text-align: center;
}
