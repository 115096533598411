.ButtonAddProduct {
  border: 1px solid rgb(0, 0, 0);
  padding: 10px;
  border-radius: 10px;
  margin-left: 1rem;
  color: white;
  background-color: #283747;
  font-size: 1.2rem;
  cursor: pointer;
  text-align: center; /* Added to center the text */
}
.ButtonAddProduct:hover {
  background-color: rgb(250, 247, 247);
  color: rgb(228, 55, 55);
  transition: 0.5s;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.3rem;
}
.SelectUniteDeMesureContener {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  box-shadow: 0px 0px 10px 0px #000000;
  margin-top: 10px;
  margin-bottom: 10px;
}

.SelectUnite {
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px #000000;
  padding: 5px;
}
.SelectedUnite {
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px #c68787;
  padding: 5px;
  margin: 5px;
}
