.RowDisplayMatiereOrderMatiere {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin: 5px;
  border-radius: 15px;
  background-color: rgba(255, 255, 0, 0.507);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  transition: 0.3s;
}
